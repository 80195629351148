import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";

import actions from "./actions";
import mutations from "./mutations";
import { config } from "../config";
import { projectRef } from "../firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    site: {
      loggedIn: false,

      // Snackbar
      show_snackbar: false,
      snackbar: {
        text: "",
        multiLine: true,
        timeout: 5000, // -1 = Never (miliseconds)
        value: "",
        absolute: true,
        centered: false, // Vertically
        vertical: false, // Taller
        color: "primary",
        elevation: 24,
      },
    },
    user: {},
    project: {},
    timesSelected: [],
    todosSelected: [],
    config,
  },
  // getters: {
  //   selected: state => state.selected
  // },
  mutations: {
    ...vuexfireMutations,
    ...mutations,
  },
  actions: {
    ...actions,
    onCreate: firestoreAction(({ state, bindFirestoreRef }) => {
      state.projectId = window.localStorage.projectId;
      bindFirestoreRef("project", projectRef(state.projectId));
    }),
    setProject: firestoreAction((_, projectId) => {
      // bindFirestoreRef("project", projectRef(projectId))
      // state.projectId = projectId;
      window.localStorage.projectId = projectId;
      window.location.reload();
    }),
  },
  modules: {},
});
