<template>
  <v-card class="punch-card">
    <v-row>
      <v-col cols="12" align="center">
        <v-text-field
          v-model="newTodo.title"
          class="punch-btn"
          label="Title"
          textarea
        ></v-text-field>

        <v-text-field
          v-model="newTodo.description"
          class="punch-btn"
          label="Description"
          textarea
        ></v-text-field>
      </v-col>

      <v-col cols="12" align="center">
        <v-btn @click="createTodo" color="primary" class="punch-btn"
          >Create New
        </v-btn>
      </v-col>

      <v-col cols="12" align="center">
        <v-btn @click="toggleCompleted" color="primary" class="punch-btn"
          >Toggle Complete
        </v-btn>
      </v-col>

      <v-col cols="12" align="center">
        <v-btn @click="deleteSelected" color="primary" class="punch-btn"
          >Delete
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { projectRef, Timestamp } from "../firebase";
import { mapState } from "vuex";

export default {
  computed: mapState(["todosSelected"]),
  data: () => ({
    newTodo: {},
  }),
  methods: {
    createTodo() {
      // if projectId in null ... error with SnackBar
      projectRef(this.$store.state.projectId)
        .collection("Todos")
        .add({ ...this.newTodo, created: Timestamp.now() });
      this.newTodo = {};
    },
    toggleCompleted() {
      for (let select of this.todosSelected) {
        projectRef(this.$store.state.projectId)
          .collection("Todos")
          .doc(select.id)
          .update({
            completed: !select.completed,
            edited: Timestamp.now(),
          });
      }
      this.$store.commit("update", { name: "todosSelected", value: [] });
    },
    deleteSelected() {
      for (let select of this.todosSelected) {
        projectRef(this.$store.state.projectId)
          .collection("Todos")
          .doc(select.id)
          .delete();
      }
      this.$store.commit("update", { name: "todosSelected", value: [] });
    },
  },
};
</script>

<style>
.punch-card {
  height: 400px;
}
.punch-btn {
  width: 80%;
}
</style>
