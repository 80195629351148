<template>
  <v-card class="punch-card">
    <v-row>
      <v-col cols="12" align="center">
        <v-btn @click="punchIn" color="primary" class="punch-btn"
          >Punch In
        </v-btn>
      </v-col>

      <v-col cols="12" align="center">
        <v-btn @click="punchOut" color="primary" class="punch-btn"
          >Punch Out
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { projectRef, Timestamp } from "../firebase";
import { mapState } from "vuex";

export default {
  computed: mapState(["timesSelected"]),
  methods: {
    punchIn() {
      // if projectId in null ... error with SnackBar
      projectRef(this.$store.state.projectId).collection("Times").add({
        start: Timestamp.now(),
      });
    },
    punchOut() {
      for (let select of this.timesSelected) {
        let date = new Date();
        date.setMinutes(Math.ceil(date.getMinutes() / 15) * 15);
        date.setSeconds(0);

        projectRef(this.$store.state.projectId)
          .collection("Times")
          .doc(select.id)
          .update({
            end: Timestamp.fromDate(date),
          });
      }
      this.$store.commit("update", { name: "timesSelected", value: [] });
    },
  },
};
</script>

<style>
.punch-card {
  height: 400px;
}
.punch-btn {
  width: 80%;
}
</style>
