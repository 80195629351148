<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :sort-by="['start']"
    :sort-desc="[true]"
    :items-per-page="5"
    show-select
    v-bind="{ ...$props, ...$attrs }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.start"
                      label="Start Time"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.end"
                      label="End Time"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="8">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.completed="{ item }">
      <v-checkbox v-model="item.completed" disabled></v-checkbox>
    </template>

    <template v-slot:item.hours="{ item }">
      {{ calculateHours(item) }}
    </template>

    <template v-slot:item.day="{ item }">
      {{ displayDay(item) }}
    </template>

    <template v-slot:item.start="{ item }">
      {{ displayTime(item.start) || "-" }}
    </template>

    <template v-slot:item.end="{ item }">
      {{ displayTime(item.end) || "-" }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-if="showTotals" v-slot:body.append>
      <tr>
        <td>Week:</td>
        <td>{{ weekHours }}</td>
        <td>Month:</td>
        <td>{{ monthHours }}</td>
        <td>Total:</td>
        <td>{{ totalHours }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { projectRef, Timestamp } from "../firebase";
import { mapState } from "vuex";

export default {
  name: "DataTable",
  props: {
    selectedStoreAttr: String,
    headers: Array,
    items: Array,
    projectAttr: String,

    toolbarTitle: String,
    showTotals: {
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,

    editedItem: {},

    weekTimes: [],
    monthTimes: [],
  }),
  firestore() {
    return {
      weekTimes: projectRef(this.projectId)
        .collection(this.projectAttr)
        .where("start", ">", Timestamp.fromDate(this.beginningOfWeek)),
      monthTimes: projectRef(this.projectId)
        .collection(this.projectAttr)
        .where("start", ">", Timestamp.fromDate(this.firstDayOfMonth)),
    };
  },
  computed: {
    ...mapState({ projectId: "projectId" }),
    selected: {
      get() {
        return this.$store.state[this.selectedStoreAttr];
      },
      set(newSelected) {
        this.$store.commit("update", {
          name: this.selectedStoreAttr,
          value: newSelected,
        });
        return newSelected;
      },
    },

    formTitle() {
      return "Edit Item";
    },

    weekHours() {
      let total = 0;
      this.weekTimes.forEach((row) => {
        total += parseFloat(this.calculateHours(row));
      });
      return total.toFixed(2);
    },
    beginningOfWeek() {
      var t = new Date();
      t.setDate(t.getDate() - t.getDay());
      return t;
    },

    monthHours() {
      let total = 0;
      this.monthTimes.forEach((row) => {
        total += parseFloat(this.calculateHours(row));
      });
      return total.toFixed(2);
    },
    firstDayOfMonth() {
      let date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },

    totalHours() {
      let total = 0;
      this.items.forEach((row) => {
        total += parseFloat(this.calculateHours(row));
      });
      return total.toFixed(2);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val ? val : (this.dialogDelete = false);
    },
  },

  methods: {
    calculateHours(item) {
      if (item.end === undefined) {
        return 0;
      }

      return (Math.abs(item.end.toDate() - item.start.toDate()) / 36e5).toFixed(
        2
      );
    },
    displayDay(item) {
      if (!item.start) return null;
      let start = new Date(item.start.seconds * 1000);

      if (item.end) {
        let end = new Date(item.end.seconds * 1000);
        if (start.getDate() !== end.getDate()) {
          return (
            [start.getMonth() + 1, start.getDate()].join("/") +
            " - " +
            [end.getMonth() + 1, end.getDate()].join("/")
          );
        }
      }
      return [start.getMonth() + 1, start.getDate()].join("/");
    },
    displayTime(time) {
      if (!time) return null;
      let d = new Date(time.seconds * 1000);
      let minutes = d.getMinutes();
      return [d.getHours(), minutes === 0 ? "00" : minutes].join(":");
    },
    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.dialogDelete = false;

      projectRef(this.projectId)
        .collection(this.projectAttr)
        .doc(this.editedItem.id)
        .delete();
    },

    close() {
      this.dialog = false;
    },

    save() {
      projectRef(this.projectId)
        .collection(this.projectAttr)
        .doc(this.editedItem.id)
        .update({ description: this.editedItem.description });

      this.close();
    },
  },
};
</script>

<style></style>
