<template>
  <v-menu v-model="menu" rounded="lg" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on"></slot>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <div v-for="(item, index) in Projects" :key="index">
          <v-list-item @click="setProject(item.id)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
        </div>

        <slot name="createDialog"></slot>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ProjectsMenu",
  props: { Projects: Array },
  data: () => ({ menu: false }),
  methods: mapActions(["setProject"]),
};
</script>

<style></style>
