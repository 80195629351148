var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","flat":"","dark":"","color":"primary","elevation":"0"}},[(_vm.site.loggedIn)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.site.drawer = !_vm.site.drawer}}}):_vm._e(),_c('v-tabs',{staticClass:"ml-n9",attrs:{"centered":"","color":"black"}},[_vm._l((_vm.links),function(link){return _c('v-tab',{key:link.text},[_vm._v(" "+_vm._s(link.text)+" ")])}),_c('projects-menu',{attrs:{"Projects":_vm.Projects},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"elevation":"0","color":"primary","dark":""}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$store.state.project ? _vm.$store.state.project.title : "Projects")+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}},{key:"createDialog",fn:function(){return [_c('create-project-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',{attrs:{"align":"center","justify":"center"}},[_vm._v("Start Project")])],1)]}}])})]},proxy:true}])})],2),_c('v-switch',{staticClass:"mr-2 mt-5",attrs:{"color":"white"},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}},[_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? "Dark" : "Light")+" ")]),_c('div',{staticClass:"mr-5",domProps:{"textContent":_vm._s(_vm.$vuetify.theme.dark ? 'Dark' : 'Light')}}),_c('v-avatar',{staticClass:"hidden-sm-and-down",attrs:{"color":"accent darken-1 shrink","size":"32"},on:{"click":_vm.onAvatarClick}},[(_vm.photoURL)?_c('img',{attrs:{"src":_vm.photoURL,"alt":"photoURL"}}):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1)],1),_c('v-main',[_c('router-view')],1),_c('v-snackbar',{attrs:{"timeout":_vm.site.snackbar.timeout,"value":_vm.site.snackbar.value,"absolute":_vm.site.snackbar.absolute,"centered":_vm.site.snackbar.centered,"vertical":_vm.site.snackbar.vertical,"color":_vm.site.snackbar.color,"elevation":_vm.site.snackbar.elevation},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.site.show_snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.site.show_snackbar),callback:function ($$v) {_vm.$set(_vm.site, "show_snackbar", $$v)},expression:"site.show_snackbar"}},[_vm._v(" "+_vm._s(_vm.site.snackbar.text)+" ")]),_c('v-footer',{staticClass:"py-3"},[_c('span',{staticClass:"ml-auto overline"},[_vm._v(_vm._s(_vm.config.projectName)+" ©2020")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }