<template>
  <v-app>
    <v-app-bar app flat dark color="primary" elevation="0">
      <v-app-bar-nav-icon
        v-if="site.loggedIn"
        @click.stop="site.drawer = !site.drawer"
      ></v-app-bar-nav-icon>

      <v-tabs centered class="ml-n9" color="black">
        <!-- :to="{ name: link.value }" -->
        <v-tab v-for="link in links" :key="link.text">
          {{ link.text }}
        </v-tab>

        <projects-menu :Projects="Projects">
          <template v-slot:activator="{ on, attrs }">
            <v-tab elevation="0" color="primary" dark v-bind="attrs" v-on="on">
              {{
                $store.state.project ? $store.state.project.title : "Projects"
              }}
              <v-icon>mdi-menu-down</v-icon>
            </v-tab>
          </template>

          <template v-slot:createDialog>
            <create-project-dialog>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title align="center" justify="center"
                    >Start Project</v-list-item-title
                  >
                </v-list-item>
              </template>
            </create-project-dialog>
          </template>
        </projects-menu>
      </v-tabs>

      <v-switch v-model="$vuetify.theme.dark" color="white" class="mr-2 mt-5">
        {{ $vuetify.theme.dark ? "Dark" : "Light" }}
      </v-switch>
      <div v-text="$vuetify.theme.dark ? 'Dark' : 'Light'" class="mr-5"></div>

      <v-avatar
        class="hidden-sm-and-down"
        color="accent darken-1 shrink"
        size="32"
        @click="onAvatarClick"
      >
        <img v-if="photoURL" :src="photoURL" alt="photoURL" />

        <v-icon dark v-else> mdi-account-circle </v-icon>
      </v-avatar>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar
      v-model="site.show_snackbar"
      :timeout="site.snackbar.timeout"
      :value="site.snackbar.value"
      :absolute="site.snackbar.absolute"
      :centered="site.snackbar.centered"
      :vertical="site.snackbar.vertical"
      :color="site.snackbar.color"
      :elevation="site.snackbar.elevation"
    >
      {{ site.snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="site.show_snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-footer class="py-3">
      <span class="ml-auto overline">{{ config.projectName }} &copy;2020</span>
    </v-footer>
  </v-app>
</template>

<script>
// I might want to move to a header and footer component
import { mapState, mapActions } from "vuex";
import { auth, userRef } from "./firebase";

import { ProjectsMenu, CreateProjectDialog } from "./components";

export default {
  name: "App",
  components: { ProjectsMenu, CreateProjectDialog },
  data: () => ({
    Projects: [],

    links: [
      {
        text: "Dashboard",
        value: "Home",
      },
      {
        text: "Todos",
        value: "Todos",
      },
      {
        text: "Settings",
        value: "Settings",
      },
      {
        text: "Summary",
        value: "Summary",
      },
    ],
  }),
  firestore() {
    return {
      Projects: userRef().collection("Projects"),
    };
  },
  methods: {
    ...mapActions(["submitLogin", "submitLogout", "onCreate"]),
    onAvatarClick() {},
  },
  computed: {
    ...mapState(["site", "config", "vuetify"]),
    photoURL() {
      if (!auth.currentUser) return "";
      return auth.currentUser.photoURL;
    },
  },
  created() {
    this.onCreate();
  },
};
</script>

<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.active-tab {
  color: green;
}
</style>
