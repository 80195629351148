import fb from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

//https://firebase.google.com/docs/web/setup#node.js-apps
//  Initialize the cloud db to get variables and to copy data,
//  And then Use the emulator db in test
import { firebaseConfig } from "../config";

// Initialize Firebase
fb.initializeApp(
  // config.production ?
  firebaseConfig
  //  : { projectId: config.projectID }
);

// utils
const db = fb.firestore();
const auth = fb.auth();
// if (config.production) {
//   db.useEmulator("localhost", "8080")
//   auth.useEmulator("http://localhost:9099")
// }

// collection references
const usersRef = db.collection("users");
const userRef = () => usersRef.doc(auth.currentUser.uid);
const projectRef = (projectId) =>
  userRef().collection("Projects").doc(projectId);
const varsRef = db.collection("variables");

const { Timestamp, GeoPoint } = fb.firestore;

// export utils/refs
export {
  fb,
  db,
  auth,
  usersRef,
  userRef,
  varsRef,
  projectRef,
  Timestamp,
  GeoPoint,
};
