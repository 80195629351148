import Vue from "vue";

var config = {
  projectName: "Mc-TimeSheet",
  projectID: "mc-timesheet",
  projectRegion: "us-central1",
  production: process.env.NODE_ENV === "production",

  functionsPort: "5001",
};

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyCmRT3XKUBXgRkz-Ok09vLv23A96-kcqOw",
  authDomain: "mc-timesheet.firebaseapp.com",
  projectId: "mc-timesheet",
  storageBucket: "mc-timesheet.appspot.com",
  messagingSenderId: "407451448048",
  appId: "1:407451448048:web:fdfc2e2925d82e6615c835",
  measurementId: "G-QB14GE3JN1",
};

Vue.prototype.$config = config;

export { config, firebaseConfig };
